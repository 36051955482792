import React, { useState, useContext, ReactNode } from 'react';

export interface ILoggedUser {
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  roles?: string[];
}

interface IUserContext {
  user: ILoggedUser | null;
  isDevicePanelBackClicked: boolean;
  handleUser: (user: ILoggedUser) => void;
  setClickState: (isClicked: boolean) => void;
}

export const useUserContext = () => {
  return useContext(UserContext);
};

const UserContext = React.createContext({} as IUserContext);

export const UserProvider: React.FC<ReactNode> = ({ children }) => {
  const [user, setUser] = useState<ILoggedUser | null>(null);
  const [isDevicePanelBackClicked, setIsDevicePanelBackClicked] =
    useState(false);

  const handleUser = (user: ILoggedUser) => {
    if (user) setUser(user);
  };

  const setClickState = (isClicked: boolean) => {
    setIsDevicePanelBackClicked(isClicked);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        isDevicePanelBackClicked,
        handleUser,
        setClickState
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
