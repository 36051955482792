import React from 'react';
import * as yup from 'yup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/pl';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { CustomInput } from 'src/components/CustomInput';
import { CustomButton } from 'src/components/CustomButton';
import { useDeviceWizzardContext } from 'src/contexts/AddDeviceWizzard';
import { useDeviceInfoStepStyles } from '../DeviceInfoStep/DeviceInfoStep.styles';
import { FormHelperText, InputAdornment } from '@material-ui/core';
import { axiosInstace, IErrorResponse } from 'src/services/axiosInstance';
import { AxiosError } from 'axios';
import { useSnackbarContext } from 'src/components/SnackBar/SnackbarProvider';
import { Snackbars } from 'src/components/SnackBar/Snackbar.types';

interface IGeneratedEui {
  deviceEui: string;
}

interface IFormInput {
  deviceEui: string;
  appKey: string;
  joinEui: string;
  messageGenerationPeriodSeconds: number;
  loraStorePeriodSeconds: number;
  expirationDate: string;
  legalizationEndDate: string;
}

const deviceInfoValidationSchema = yup.object().shape({
  deviceEui: yup.string().max(255).required('Pole obowiązkowe'),
  joinEui: yup.string().max(255),
  appKey: yup.string().max(255).required('Pole obowiązkowe'),
  messageGenerationPeriodSeconds: yup
    .number()
    .typeError('Podaj liczbę sekund > 3600')
    .required('Pole obowiązkowe'),
  loraStorePeriodSeconds: yup
    .number()
    .typeError('Podaj liczbę sekund > 3600')
    .required('Pole obowiązkowe'),
  expirationDate: yup.string().required('Pole obowiązkowe'),
  legalizationEndDate: yup.string().required('Pole obowiązkowe')
});

interface DeviceRadioParamStepProps {
}

export const DeviceRadioParamStep: React.FC<DeviceRadioParamStepProps> = () => {
  const classes = useDeviceInfoStepStyles();
  const { updateDevice, handleBack, deviceWizzard, handleNext } =
    useDeviceWizzardContext();
  const { openSnackBar } = useSnackbarContext();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<IFormInput>({
    defaultValues: {
      deviceEui: deviceWizzard?.deviceTtnSettings?.deviceEui || '',
      joinEui: deviceWizzard?.deviceTtnSettings?.joinEui || '',
      appKey: deviceWizzard?.deviceTtnSettings?.appKey || '',
      loraStorePeriodSeconds: deviceWizzard?.loraStorePeriodSeconds || 0,
      messageGenerationPeriodSeconds:
        deviceWizzard?.messageGenerationPeriodSeconds || 0,
      expirationDate: deviceWizzard?.expirationDate || '',
      legalizationEndDate: deviceWizzard?.legalizationEndDate || ''
    },
    resolver: yupResolver(deviceInfoValidationSchema)
  });

  const onSubmit: SubmitHandler<IFormInput> = async ({
                                                       deviceEui,
                                                       joinEui,
                                                       appKey,
                                                       loraStorePeriodSeconds,
                                                       messageGenerationPeriodSeconds,
                                                       expirationDate,
                                                       legalizationEndDate
                                                     }) => {
    updateDevice({
      deviceTtnSettings: {
        deviceEui: deviceEui,
        joinEui: joinEui,
        appKey: appKey
      },
      loraStorePeriodSeconds,
      messageGenerationPeriodSeconds,
      expirationDate,
      legalizationEndDate
    });
    handleNext();
  };
  const getDevEui = () => {
    axiosInstace
      .get<IGeneratedEui>(
        `/api/devices/availableIds/${deviceWizzard?.deviceType
          ?.charAt(0)
          .toUpperCase()}${deviceWizzard?.deviceType?.slice(1)}/${
          deviceWizzard?.networkType
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.deviceEui) {
            setValue('deviceEui', response.data.deviceEui);
          }
        }
      })
      .catch((err) => {
        if (err && err.response) {
          const axiosError = err as AxiosError<IErrorResponse>;
          if (axiosError.response?.data.errors) {
            openSnackBar(axiosError.response?.data.errors[0], Snackbars.ERROR);
          }
          if (axiosError.response?.data.title)
            openSnackBar(axiosError.response?.data.title, Snackbars.ERROR);
        }
        throw err;
      });
  };

  return (
    <>
      <Controller
        name="deviceEui"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.deviceEui}
            helperText={
              errors.deviceEui &&
              errors.deviceEui?.message &&
              errors.deviceEui.message
            }
            label="EUI urządzenia"
            autoComplete="EUI urządzenia"
            ref={null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CustomButton
                    variant="contained"
                    color="primary"
                    onClick={getDevEui}
                    disabled={
                      deviceWizzard?.networkType === 'Emitel' ? true : false
                    }
                  >
                    Generuj EUI
                  </CustomButton>
                </InputAdornment>
              )
            }}
          />
        )}
      />
      <Controller
        name="joinEui"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.joinEui}
            helperText={
              errors.joinEui &&
              errors.joinEui?.message &&
              errors.joinEui.message
            }
            disabled={deviceWizzard?.networkType === 'Emitel' ? true : false}
            label={
              deviceWizzard?.networkType === 'Emitel'
                ? 'JOIN EUI nie jest wymagane w sieci Emitel'
                : 'Join EUI'
            }
            autoComplete="Join EUI"
            ref={null}
          />
        )}
      />
      <Controller
        name="appKey"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.appKey}
            helperText={
              errors.appKey && errors.appKey?.message && errors.appKey.message
            }
            label="APP KEY"
            autoComplete="APP KEY"
            ref={null}
          />
        )}
      />
      <Controller
        name="messageGenerationPeriodSeconds"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.messageGenerationPeriodSeconds}
            helperText={
              errors.messageGenerationPeriodSeconds &&
              errors.messageGenerationPeriodSeconds?.message &&
              errors.messageGenerationPeriodSeconds.message
            }
            label="Interwał wysyłania pakietu"
            autoComplete="Interwał wysyłania pakietu"
            ref={null}
          />
        )}
      />
      <Controller
        name="loraStorePeriodSeconds"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.loraStorePeriodSeconds}
            helperText={
              errors.loraStorePeriodSeconds &&
              errors.loraStorePeriodSeconds?.message &&
              errors.loraStorePeriodSeconds.message
            }
            label="Interwał pomiaru"
            autoComplete="Interwał pomiaru"
            ref={null}
          />
        )}
      />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 16, gap: 16 }}>
        <Controller
          name="expirationDate"
          control={control}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pl'}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <DatePicker
                  label={'Data ważności urządzenia'} onChange={(date: Dayjs | null) => {
                  field.onChange(date?.format('YYYY-MM-DD'));
                }} />
                <FormHelperText style={{ color: '#f44336' }}>
                  {errors.expirationDate &&
                    errors.expirationDate?.message &&
                    errors.expirationDate.message}
                </FormHelperText>
              </div>
            </LocalizationProvider>
          )}
        />
        <Controller
          name="legalizationEndDate"
          control={control}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pl'}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <DatePicker label={'Data legalizacji urządzenia'}
                            onChange={(date: Dayjs | null) => {
                              field.onChange(date?.format('YYYY-MM-DD'));
                            }} />
                <FormHelperText style={{ color: '#f44336' }}>
                  {errors.legalizationEndDate &&
                    errors.legalizationEndDate?.message &&
                    errors.legalizationEndDate.message}
                </FormHelperText>
              </div>
            </LocalizationProvider>
          )}
        />
      </div>

      <div className={classes.buttons}>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={handleBack}
          className={classes.submitText}
        >
          Wróć
        </CustomButton>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          className={classes.submitText}
        >
          Dodaj urządzenie
        </CustomButton>
      </div>
    </>
  );
};
