import { makeStyles } from '@material-ui/core';

export const useStepperStyles = makeStyles(
  (theme) => ({
    root: {
      paddingTop: theme.spacing(8),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center'
    },
    stepperContainer: {
      backgroundColor: theme.palette.background.default
    },
    instructions: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  }),
  {
    name: 'Stepper'
  }
);
