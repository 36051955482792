import { makeStyles } from '@material-ui/core';

export const useCustomTableStyles = makeStyles(
  (theme) => ({
    cell: {
      color: '#ffffff'
    },
    tableHead: {
      backgroundColor: '#2c387e'
    }
  }),
  {
    name: 'CustomTable'
  }
);
