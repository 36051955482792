import { useTheme, useMediaQuery } from '@material-ui/core';

export const useShowMobile = () => {
  const theme = useTheme();
  const showMobileMenu = useMediaQuery(theme.breakpoints.down('sm'));

  return {
    showMobileMenu
  };
};
