import { makeStyles } from '@material-ui/core';

export const useCustomInputStyles = makeStyles(
  (theme) => ({
    test: {
      '& label.Mui-focused': {
        color: theme.palette.common.black
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.common.black
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.common.black
        },
        '&:hover fieldset': {
          borderColor: theme.palette.common.black
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.common.black
        }
      }
    }
  }),
  {
    name: 'CustomInputStyles'
  }
);
