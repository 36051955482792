import * as yup from 'yup';
import { AxiosError } from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@material-ui/core';
import { CustomButton } from 'src/components/CustomButton';
import { CustomInput } from 'src/components/CustomInput';
import { destroyCookie, setCookie } from 'src/services/cookiesService';
import { Routes } from 'src/routes/routes';
import { useSnackbarContext } from 'src/components/SnackBar/SnackbarProvider';
import { Snackbars } from 'src/components/SnackBar/Snackbar.types';
import { axiosInstace, IErrorResponse } from 'src/services/axiosInstance';
import { useLoginFormStyles } from './LoginForm.styles';
import { ILoggedUser } from 'src/contexts/UserProvider';

interface IFormInput {
  email: string;
  password: string;
}

interface ILoginResponseSuccess {
  accessToken: string;
  expires: string;
  id: string;
}

const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Niepoprawny e-mail')
    .max(255)
    .required('Pole obowiązkowe'),
  password: yup
    .string()
    .min(8, 'Hasło musi zawierać 8 znaków')
    .max(255)
    .required('Pole obowiązkowe')
});

export const LoginForm: React.FC = (): JSX.Element => {
  const classes = useLoginFormStyles();
  const history = useHistory();
  const { openSnackBar } = useSnackbarContext();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IFormInput>({
    resolver: yupResolver(loginValidationSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const onSubmit: SubmitHandler<IFormInput> = ({ email, password }) => {
    axiosInstace
      .post<ILoginResponseSuccess>('/api/account/login', {
        email,
        password
      })
      .then((response) => {
        if (response.status === 200) {
          axiosInstace.get<ILoggedUser>('/api/account/my').then((res) => {
            if (res.data.roles)
              if (res.data.roles[0] !== 'Administrator') {
                destroyCookie('at');
                history.replace(Routes.LOGIN);
                openSnackBar('Brak uprawnień', Snackbars.ERROR);
                return;
              }
          });
          const expirationTime = new Date(response.data.expires);
          setCookie({
            name: 'at',
            token: response.data.accessToken,
            expirationTime: expirationTime
          });
          openSnackBar('Udane logowanie', Snackbars.SUCCESS);
          history.push(Routes.ADD_DEVICES);
        } else {
          openSnackBar('Spróbuj ponownie', Snackbars.ERROR);
        }
      })
      .catch((err) => {
        if (err && err.response) {
          const axiosError = err as AxiosError<IErrorResponse>;
          if (axiosError.response?.data.title)
            openSnackBar(axiosError.response?.data.title, Snackbars.ERROR);
        }

        throw err;
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.email}
            helperText={
              errors.email && errors.email?.message && errors.email.message
            }
            label="Email"
            autoComplete="email"
            ref={null}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.password}
            helperText={
              errors.password &&
              errors.password?.message &&
              errors.password.message
            }
            label="Hasło"
            type="password"
            autoComplete="current-password"
            ref={null}
          />
        )}
      />
      <CustomButton>
        <Typography className={classes.submitText}> Zaloguj </Typography>
      </CustomButton>
      <Grid container>
        <Grid item xs>
          <Link
            to={Routes.RESET_PASSWORD}
            className={classes.submitTextPassword}
          >
            Zapomniałeś hasła?
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};
