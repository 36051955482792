import { makeStyles } from '@material-ui/core';

const drawerWidth = 240;

export const useDashboardStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row'
    },
    mobileRoot: { display: 'flex', flexDirection: 'column' },
    menuMobile: {
      maxHeight: 0,
      transition: 'max-height 0.8s'
    },
    menuMobileOpened: {
      maxHeight: '200px'
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      ...theme.mixins.toolbar
    },
    title: {
      flexGrow: 1,
      color: theme.palette.common.white
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      backgroundColor: theme.palette.background.default
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column'
    },
    copyright: {
      padding: '0 10px'
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9)
      }
    },
    appBarSpacer: theme.mixins.toolbar,
    toolbar: {
      paddingRight: 24
    },
    menuButton: {
      marginRight: 18
    },
    menuButtonHidden: {
      display: 'none'
    }
  }),
  { name: 'Dashboard' }
);
