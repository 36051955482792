import { makeStyles } from '@material-ui/core';

export const useDeviceInfoStepStyles = makeStyles(
  (theme) => ({
    container: {
      height: '100%',
      width: '100%',
      marginTop: theme.spacing(4)
    },
    buttons: {
      margin: `0 auto`,
      marginTop: theme.spacing(4),
      width: '50%'
    },
    instructions: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    submitText: {
      textDecoration: 'none',
      color: theme.palette.common.white,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    select: {
      width: '100%'
    },
    selectors: {
      marginBottom: 30
    }
  }),
  {
    name: 'DeviceInfoStep'
  }
);
