import { axiosInstace } from 'src/services/axiosInstance';
import { AxiosResponse } from 'axios';

import { Endpoint } from '../../endpoint';

export interface ICustomerResponse {
  id: string;
  name: string;
}

export const getCustomers = (): Promise<AxiosResponse<ICustomerResponse[]>> => {
  return axiosInstace.get(Endpoint.CUSTOMERS_LIST);
};
