import { makeStyles } from '@material-ui/core';

export const useResetPasswordStyles = makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100vh'
    },
    form: {
      maxWidth: '300px',
      marginTop: theme.spacing(1)
    },
    submitText: {
      textDecoration: 'none',
      color: theme.palette.common.white
    },
    headerText: {
      color: theme.palette.common.black,
      fontSize: '20px'
    },
    loginButton: {
      maxWidth: '300px',
      backgroundColor: theme.palette.common.black
    }
  }),
  {
    name: 'ResetPassword'
  }
);
