import React from 'react';
import * as yup from 'yup';
import { Grid, Typography } from '@material-ui/core';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbarContext } from 'src/components/SnackBar/SnackbarProvider';
import { useResetPasswordStyles } from './ResetPassword.styles';
import { axiosInstace, IErrorResponse } from 'src/services/axiosInstance';
import { Snackbars } from 'src/components/SnackBar/Snackbar.types';
import { AxiosError } from 'axios';
import { CustomInput } from 'src/components/CustomInput';
import { CustomButton } from 'src/components/CustomButton';
import { Logo } from 'src/components/Logo';
import { Routes } from 'src/routes/routes';

interface IFormInput {
  email: string;
}

const resetPasswordEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email('Niepoprawny e-mail')
    .max(255)
    .required('Pole obowiązkowe')
});

interface ResetPasswordProps {}

export const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const classes = useResetPasswordStyles();
  const { openSnackBar } = useSnackbarContext();
  const history = useHistory();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<IFormInput>({
    resolver: yupResolver(resetPasswordEmailSchema),
    defaultValues: {
      email: ''
    }
  });

  const onSubmit: SubmitHandler<IFormInput> = ({ email }) => {
    axiosInstace
      .post('/api/account/password/reset/init', {
        email
      })
      .then((response) => {
        reset();
        if (response.status === 204) {
          openSnackBar('Sprawdź adres email', Snackbars.SUCCESS);
        } else {
          openSnackBar('Spróbuj ponownie', Snackbars.ERROR);
        }
      })
      .catch((err) => {
        reset();
        if (err && err.response) {
          const axiosError = err as AxiosError<IErrorResponse>;
          if (axiosError.response?.data.title)
            openSnackBar(axiosError.response?.data.title, Snackbars.ERROR);
        }

        throw err;
      });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={classes.container}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Logo width="300" />
        <Typography align="center" className={classes.headerText}>
          Przypomnij hasło
        </Typography>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <CustomInput
              {...field}
              error={!!errors.email}
              helperText={
                errors.email && errors.email?.message && errors.email.message
              }
              label="Email"
              autoComplete="email"
              ref={null}
            />
          )}
        />
        <div>
          <CustomButton>
            <Typography className={classes.submitText}>
              Prześlij link aktywacyjny
            </Typography>
          </CustomButton>
        </div>
      </form>
      <CustomButton
        className={classes.loginButton}
        onClick={() => {
          history.push(Routes.LOGIN);
        }}
      >
        <Typography className={classes.submitText}>
          Wróc do logowania
        </Typography>
      </CustomButton>
    </Grid>
  );
};
