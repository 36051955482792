import React from 'react';
import { Route } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Routes } from 'src/routes/routes';
import { Accounts } from './Accounts';
import { NewAccounts } from './NewAccount';
import { useSettingsStyles } from './Settings.styles';

interface SettingsProps {}

export const Settings: React.FC<SettingsProps> = () => {
  const classes = useSettingsStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      className={classes.container}
    >
      <Route path={Routes.ACCOUNTS} component={Accounts} />
      <Route path={Routes.NEW_ACCOUNT} component={NewAccounts} />
    </Grid>
  );
};
