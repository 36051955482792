import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { NewAccountForm } from './NewAccount.form';
import { useNewAccountsStyles } from './NewAccounts.styles';

interface NewAccountsProps {}

export const NewAccounts: React.FC<NewAccountsProps> = () => {
  const classes = useNewAccountsStyles();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <Typography variant="h6" align="center">
        Dodaj nowego administratora
      </Typography>
      <NewAccountForm />
    </Grid>
  );
};
