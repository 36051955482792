import React, { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { Logo } from 'src/components/Logo';
import { Routes } from 'src/routes/routes';
import { LoginForm } from './components/LoginForm';
import { axiosInstace, IErrorResponse } from 'src/services/axiosInstance';
import { ILoggedUser } from 'src/contexts/UserProvider';
import { useLoginStyles } from './Login.styles';

export const Login: React.FC = (): JSX.Element => {
  const classes = useLoginStyles();
  const history = useHistory();

  useEffect(() => {
    axiosInstace
      .get<ILoggedUser>('/api/account/my')
      .then((response) => {
        if (response.status === 200) history.replace(Routes.ADD_DEVICES);
        else history.replace('/');
      })
      .catch((err) => {
        if (err && err.response) {
          const axiosError = err as AxiosError<IErrorResponse>;
          return axiosError.response?.data;
        }
        throw err;
      });
  }, [history]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <Grid className={classes.paper}>
        <Logo width="300" />
        <Typography component="h1" variant="h5">
          Logowanie
        </Typography>
        <LoginForm />
      </Grid>
    </Grid>
  );
};
