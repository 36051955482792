import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Route } from 'react-router-dom';
import { Routes } from 'src/routes/routes';
import { ChangeProfileData } from './ChangeProfileData';
import { ChangePassword } from './ChangePassword';

export const MyProfile: React.FC = () => {
  return (
    <Grid
      container
      alignContent="center"
      justifyContent="center"
      direction="column"
    >
      <Route path={Routes.MY_PROFILE} component={ChangeProfileData} />
      <Route path={Routes.CHANGE_PASSWORD} component={ChangePassword} />
    </Grid>
  );
};
