import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Snackbars } from 'src/components/SnackBar/Snackbar.types';
import { axiosInstace, IErrorResponse } from 'src/services/axiosInstance';

type ParamType = {
  id: string;
  value: string;
};

interface IHeatMeter {
  diameters: ParamType[];
  measurementClasses: ParamType[];
  flows: ParamType[];
  lengths: ParamType[];
  producers: ParamType[];
}

export const useHeatMeterParam = () => {
  const [heatMeterParam, setHeatMeterParam] = useState<IHeatMeter | null>(null);

  const getHeatMeterParam = useCallback(() => {
    axiosInstace
      .get<IHeatMeter>('/api/heatMeters/properties')
      .then((response) => {
        if (response.status === 200) {
          setHeatMeterParam(response.data);
        }
      })
      .catch((err) => {
        if (err && err.response) {
          const axiosError = err as AxiosError<IErrorResponse>;
          if (axiosError.response?.data.errors) {
            console.error(axiosError.response?.data.errors[0], Snackbars.ERROR);
          }
          if (axiosError.response?.data.title)
            console.error(axiosError.response?.data.title, Snackbars.ERROR);
        }
        throw err;
      });
  }, []);

  useEffect(() => {
    getHeatMeterParam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    heatMeterParam
  };
};
