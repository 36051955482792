import React, { ReactNodeArray } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { useCustomTableStyles } from './CustomTable.styles';

interface PaginationProps {
  headTitles: string[];
  rows: ReactNodeArray;
}

export const CustomTable: React.FC<PaginationProps> = ({
  headTitles,
  rows
}) => {
  const classes = useCustomTableStyles();

  return (
    <Table>
      <TableHead className={classes.tableHead}>
        <TableRow>
          {headTitles.map((title) => (
            <TableCell key={title} className={classes.cell}>
              {title}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </Table>
  );
};
