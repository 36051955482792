import { makeStyles } from '@material-ui/core';

export const useSettingsStyles = makeStyles(
  (theme) => ({
    container: {
      height: 'calc(100% - 150px)'
    },
    submit: {
      textDecoration: 'none',
      color: 'inherit'
    }
  }),
  {
    name: 'Settings'
  }
);
