import { useQuery } from 'react-query';

import { getCustomers } from '../infrastructure/get-customers';

export const useGetCustomers = () => {

  return useQuery({
    queryFn: () => getCustomers(),
    keepPreviousData: true
  });
};
