import { makeStyles } from '@material-ui/core';

export const useAppStyles = makeStyles(
  (theme) => ({
    container: {
      backgroundColor: theme.palette.background.paper
    }
  }),
  {
    name: 'App'
  }
);
