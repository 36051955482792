import { makeStyles } from '@material-ui/core';

export const useMenuItemsStyles = makeStyles(
  (theme) => ({
    submit: {
      textDecoration: 'none',
      color: 'inherit'
    }
  }),
  {
    name: 'MenuItems'
  }
);
