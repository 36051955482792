import React from 'react';
import { ProfileForm } from './MyProfile.form';
import { Grid } from '@material-ui/core';
import { useMyProfileStyles } from './MyProfile.styles';

export const ChangeProfileData: React.FC = () => {
  const classes = useMyProfileStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={classes.container}
    >
      <ProfileForm />
    </Grid>
  );
};
