import { makeStyles } from '@material-ui/core';

export const useMobileMenuStyles = makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column'
    },
    menuMobileTop: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      padding: '10px 0'
    },
    menuMobile: {
      maxHeight: 0,
      transition: '0.7s',
      overflow: 'hidden'
    },
    menuMobileOpened: {
      maxHeight: '600px',
      transition: '0.7s',
      overflow: 'hidden'
    }
  }),
  { name: 'MobileMenu' }
);
