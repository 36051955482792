import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddBoxIcon from '@material-ui/icons/AddBox';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { destroyCookie } from 'src/services/cookiesService';
import { Routes } from 'src/routes/routes';

enum Options {
  ADD_DEVICES = 'Dodaj urządzenie',
  USERS = 'Użytkownicy',
  ADD_USER = 'Dodaj użytkownika',
  MY_PROFILE = 'Profil',
  CHANGE_PASSWORD = 'Hasło',
  LOGOUT = 'Wyloguj'
}

interface MenuItem {
  name: Options;
  path: string;
  icon: any;
  onClick?: () => void;
}

const ICON_COLOR = { color: 'black' };

export const MenuOptions: Array<MenuItem> = [
  // {
  //   name: Options.DEVICES,
  //   path: Routes.DEVICES_LIST_CITY,
  //   icon: <SettingsRemoteIcon style={ICON_COLOR} />
  // },
  {
    name: Options.ADD_DEVICES,
    path: Routes.ADD_DEVICES,
    icon: <AddBoxIcon style={ICON_COLOR} />
  },
  // {
  //   name: Options.LOCALISATIONS,
  //   path: Routes.LOCALISATION_LIST,
  //   icon: <EditLocation style={ICON_COLOR} />
  // },
  // {
  //   name: Options.ADD_LOCALISATIONS,
  //   path: Routes.ADD_LOCALISATION,
  //   icon: <LocationCityIcon style={ICON_COLOR} />
  // },
  {
    name: Options.USERS,
    path: Routes.ACCOUNTS,
    icon: <SupervisorAccountIcon style={ICON_COLOR} />
  },
  {
    name: Options.ADD_USER,
    path: Routes.NEW_ACCOUNT,
    icon: <AddBoxIcon style={ICON_COLOR} />
  },
  {
    name: Options.MY_PROFILE,
    path: Routes.MY_PROFILE,
    icon: <AccountBoxIcon style={ICON_COLOR} />
  },
  {
    name: Options.CHANGE_PASSWORD,
    path: Routes.CHANGE_PASSWORD,
    icon: <VpnKeyIcon style={ICON_COLOR} />
  },
  {
    name: Options.LOGOUT,
    path: Routes.LOGIN,
    icon: <ExitToAppIcon style={ICON_COLOR} />,
    onClick: () => {
      destroyCookie('at');
    }
  }
];
