import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ResetPassword } from 'src/pages/ResetPassword';
import { NewPassword } from 'src/pages/ResetPassword/NewPassword.form';
import { Dashboard } from '../pages/Dashboard';
import { Login } from '../pages/Login';
import { Routes } from './routes';

export const MainRoutes: React.FC = (): JSX.Element => {
  return (
    <Router>
      <Switch>
        <Route path={Routes.DASHBOARD} component={Dashboard} />
        <Route path={Routes.LOGIN} exact component={Login} />
        <Route path={Routes.RESET_PASSWORD} component={ResetPassword} />
        <Route path={Routes.NEW_PASSWORD} component={NewPassword} />
      </Switch>
    </Router>
  );
};
