import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { useCustomInputStyles } from './CustomInput.styles';

export const CustomInput = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    const classes = useCustomInputStyles();
    return (
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        autoFocus
        inputRef={ref}
        classes={{ root: classes.test }}
        {...props}
      />
    );
  }
);
