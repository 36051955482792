import { makeStyles } from '@material-ui/core';

export const useChangePasswordStyles = makeStyles(
  (theme) => ({
    form: {
      width: '300px',
      marginTop: theme.spacing(4)
    },
    submitText: {
      textDecoration: 'none',
      color: theme.palette.common.white
    },
    header: {
      fontSize: '20px',
      fontWeight: 'bold'
    }
  }),
  {
    name: 'ChangePassword'
  }
);
