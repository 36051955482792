import React, { FC, ReactNode } from 'react';
import { Stepper, Step, StepLabel, Typography } from '@material-ui/core/';
import { useStepperStyles } from './Stepper.styles';
import { useDeviceWizzardContext } from 'src/contexts/AddDeviceWizzard';

const getSteps = (): string[] => {
  return ['Montaż', 'Parametry mechaniczne', 'Parametry radiowe', 'Aktywacja'];
};

interface ICustomStepper {
  getStepContent: (step: number) => ReactNode;
}

export const CustomStepper: FC<ICustomStepper> = ({
  getStepContent
}): JSX.Element => {
  const classes = useStepperStyles();
  const { activeStep } = useDeviceWizzardContext();
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Stepper className={classes.stepperContainer} activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: React.ReactNode } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
            </div>
          ) : (
            <div>{getStepContent(activeStep)}</div>
          )}
        </div>
      </div>
    </div>
  );
};
