import { makeStyles } from '@material-ui/core';

export const useAccountsStyles = makeStyles(
  (theme) => ({
    container: {
      width: '100%'
    },
    cell: {
      color: '#ffffff'
    },
    tableHead: {
      backgroundColor: '#2c387e'
    }
  }),
  {
    name: 'Accounts'
  }
);
