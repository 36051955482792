import { useQuery } from 'react-query';

import { queryKey } from './query-key.enum';
import { getBuildings } from '../infrastructure/get-buildings';

type TBuildingProps = {
  streetId: string;
  tenantId: string;
};

export const useGetBuildingsQuery = ({ streetId, tenantId }: TBuildingProps) => {
  return useQuery({
    queryKey: queryKey.BUILDINGS_BY_STREET(streetId),
    queryFn: () => getBuildings({ streetId, tenantId }),
    keepPreviousData: true,
    enabled: false,
  });
};
