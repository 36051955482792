export enum Routes {
  LOGIN = '/',
  RESET_PASSWORD = '/password/reset/init',
  NEW_PASSWORD = '/password/reset',
  DASHBOARD = '/app',
  PROFILE = '/app/profile',
  MY_PROFILE = '/app/profile/my',
  CHANGE_PASSWORD = '/app/profile/password',
  ADD_DEVICES = '/app/devices/add',
  MANAGE = '/app/settings',
  NEW_ACCOUNT = '/app/settings/new-account',
  ACCOUNTS = '/app/settings/accounts',
  DEVICES = '/app/devices',
// PROFILE
// DEVICES
// DEVICES_LIST = '/app/devices/overview',
// DEVICES_LIST_CITY = '/app/devices/overview/cities',
// DEVICES_LIST_STREETS = '/app/devices/overview/cities/:cityName',
// DEVICES_LIST_BUILDINGS = '/app/devices/overview/cities/:cityName/:streetId',
// DEVICES_LIST_DEVICES = '/app/devices/overview/cities/:cityName/:streetId/:addressId/:selectedDevice?',
// DEVICE_PANEL = '/app/selected-device/:selectedDevice',
// DEVICES_ALERTS = '/app/devices/alerts',
// LOCALISATIONS //
// LOCALISATION = '/app/localisations',
// LOCALISATION_LIST = '/app/localisations/list',
// ADD_LOCALISATION = '/app/localisations/add',
// SETTINGS //
}
