import { Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { CustomStepper } from 'src/components/Stepper';
import { DeviceWizzardProvider } from 'src/contexts/AddDeviceWizzard';
import { DeviceInfoStep } from 'src/pages/Dashboard/Devices/AddDevice/AddDeviceSteps/DeviceInfoStep';
import { DeviceRadioParamStep } from 'src/pages/Dashboard/Devices/AddDevice/AddDeviceSteps/DeviceRadioParamStep';
import { DeviceActivation } from './AddDeviceSteps/DeviceActivation';
import { DeviceParams } from './AddDeviceSteps/DeviceParams/DeviceParams';

const getStepContent = (step: number): ReactNode => {
  switch (step) {
    case 0:
      return <DeviceInfoStep />;
    case 1:
      return <DeviceParams />;
    case 2:
      return <DeviceRadioParamStep />;
    case 3:
      return <DeviceActivation />;
    default:
      return <Typography>Unknown step</Typography>;
  }
};

export const AddDevice: React.FC = () => {
  return (
    <DeviceWizzardProvider>
      <CustomStepper getStepContent={getStepContent} />
    </DeviceWizzardProvider>
  );
};
