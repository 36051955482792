import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { useCustomButtonStyles } from './CustomButton.styles';

export const CustomButton: React.FC<ButtonProps> = (props): JSX.Element => {
  const classes = useCustomButtonStyles();
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      className={classes.button}
      {...props}
    >
      {props.children}
    </Button>
  );
};
