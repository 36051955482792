import { Link as RouterLink } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { MenuOptions } from './MenuOptions';
import { useMenuItemsStyles } from './MenuItems.styles';

interface MenuItemsProps {
  mobileTransition?: string;
}

export const MenuItems: React.FC<MenuItemsProps> = ({
  mobileTransition
}): JSX.Element => {
  const classes = useMenuItemsStyles();

  return (
    <div className={mobileTransition}>
      {MenuOptions.map(({ name, path, icon, onClick }) => (
        <div key={path}>
          <RouterLink
            key={path}
            className={classes.submit}
            to={path}
            onClick={onClick}
          >
            <ListItem button>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          </RouterLink>
          <Divider />
        </div>
      ))}
    </div>
  );
};
