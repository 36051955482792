import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { axiosInstace, IErrorResponse } from 'src/services/axiosInstance';
import { AxiosError } from 'axios';
import { useSnackbarContext } from 'src/components/SnackBar/SnackbarProvider';
import { Snackbars } from 'src/components/SnackBar/Snackbar.types';
import { CustomInput } from 'src/components/CustomInput';
import { CustomButton } from 'src/components/CustomButton';
import { useChangePasswordStyles } from './ChangePassword.styles';
import { Grid, Typography } from '@material-ui/core';

interface IFormInput {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

const changePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .max(100, 'Hasło nie może przekraczać 100 znaków')
    .min(8, 'Hasło musi mieć conajmniej 8 znaków')
    .required('Hasło jest wymagane'),
  password: yup
    .string()
    .max(100, 'Hasło nie może przekraczać 100 znaków')
    .min(8, 'Hasło musi mieć conajmniej 8 znaków')
    .required('Hasło jest wymagane'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Hasła muszą być takie same')
});

export const ChangePassword: React.FC = () => {
  const classes = useChangePasswordStyles();
  const { openSnackBar } = useSnackbarContext();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<IFormInput>({
    resolver: yupResolver(changePasswordSchema),
    defaultValues: {
      oldPassword: '',
      password: '',
      confirmPassword: ''
    }
  });

  const onSubmit: SubmitHandler<IFormInput> = ({
    oldPassword,
    password,
    confirmPassword
  }) => {
    axiosInstace
      .put('/api/account/my/password', {
        oldPassword,
        password,
        confirmPassword
      })
      .then((response) => {
        reset();
        if (response.status === 204) {
          openSnackBar('Hasło zmienione', Snackbars.SUCCESS);
        }
      })
      .catch((err) => {
        reset();
        if (err && err.response) {
          const axiosError = err as AxiosError<IErrorResponse>;
          if (axiosError.response?.data.errors) {
            openSnackBar(axiosError.response?.data.errors[0], Snackbars.ERROR);
            return;
          }
          if (axiosError.response?.data.title) {
            openSnackBar(axiosError.response?.data.title, Snackbars.ERROR);
            return;
          }
        }
        throw err;
      });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      direction="column"
    >
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <div>
          <Typography align="center" className={classes.header}> Zmień aktualne hasło </Typography>
        </div>
        <Controller
          name="oldPassword"
          control={control}
          render={({ field }) => (
            <CustomInput
              {...field}
              error={!!errors.oldPassword}
              helperText={
                errors.oldPassword &&
                errors.oldPassword?.message &&
                errors.oldPassword.message
              }
              type="password"
              label="Stare hasło"
              autoComplete="Stare hasło"
              ref={null}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <CustomInput
              {...field}
              error={!!errors.password}
              helperText={
                errors.password &&
                errors.password?.message &&
                errors.password.message
              }
              type="password"
              label="Nowe Hasło"
              autoComplete="Hasło"
              ref={null}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <CustomInput
              {...field}
              error={!!errors.confirmPassword}
              helperText={
                errors.confirmPassword &&
                errors.confirmPassword?.message &&
                errors.confirmPassword.message
              }
              type="password"
              label="Potwierdź nowe hasło"
              autoComplete="Potwierdź hasło"
              ref={null}
            />
          )}
        />
        <div>
          <CustomButton>
            <Typography className={classes.submitText}>
              Ustaw nowe hasło
            </Typography>
          </CustomButton>
        </div>
      </form>
    </Grid>
  );
};
