import { useQuery } from 'react-query';

import { queryKey } from './query-key.enum';
import { getFlats } from '../infrastructure/get-flats';

type TFlatProps = {
  buildingId: string;
  tenantId: string;
};

export const useGetFlatsQuery = ({ buildingId, tenantId }: TFlatProps) => {
  return useQuery({
    queryKey: queryKey.FLATS_BY_BUILDING(buildingId),
    queryFn: () => getFlats({ buildingId, tenantId}),
    keepPreviousData: true,
    enabled: false,
  });
};
