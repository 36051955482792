import React from 'react';
import { Grid } from '@material-ui/core';
import { Route } from 'react-router-dom';
import { Routes } from 'src/routes/routes';

import { AddDevice } from './AddDevice';

export const Devices: React.FC = () => {
  return (
    <Grid container direction="row" justifyContent="center">
      <Route path={Routes.ADD_DEVICES} component={AddDevice} />
      {/*<Route path={Routes.DEVICES_LIST} component={DevicesList} />*/}
    </Grid>
  );
};
