import * as yup from 'yup';
import { AxiosError } from 'axios';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import { useSnackbarContext } from 'src/components/SnackBar/SnackbarProvider';
import { CustomButton } from 'src/components/CustomButton';
import { CustomInput } from 'src/components/CustomInput';
import { Snackbars } from 'src/components/SnackBar/Snackbar.types';
import { axiosInstace, IErrorResponse } from 'src/services/axiosInstance';
import { useNewAccountsStyles } from './NewAccounts.styles';
import React from 'react';

interface IFormInput {
  name: string;
  surname: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
}

const newAccountValidationSchema = yup.object().shape({
  name: yup.string().max(255).required('Pole obowiązkowe'),
  surname: yup.string().max(255).required('Pole obowiązkowe'),
  email: yup.string().email('Niepoprawny e-mail').required('Pole obowiązkowe'),
  password: yup
    .string()
    .max(100, 'Hasło nie może przekraczać 100 znaków')
    .min(8, 'Hasło musi mieć conajmniej 8 znaków')
    .required('Hasło jest wymagane'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Hasła muszą być takie same'),
  phoneNumber: yup
    .string()
    .min(9, 'Numer telefonu musi mieć conajmniej 9 cyfr')
    .matches(/^[0-9]+$/, 'Numer telefonu powinien składać się tylko z cyfr')
});

export const NewAccountForm: React.FC = (): JSX.Element => {
  const classes = useNewAccountsStyles();
  const { openSnackBar } = useSnackbarContext();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<IFormInput>({
    resolver: yupResolver(newAccountValidationSchema),
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      password: '',
      confirmPassword: '',
      phoneNumber: ''
    }
  });

  const onSubmit: SubmitHandler<IFormInput> = async (
    { name, surname, email, password, confirmPassword, phoneNumber },
    e
  ) => {
    axiosInstace
      .post('/api/account', {
        name,
        surname,
        email,
        password,
        confirmPassword,
        phoneNumber
      })
      .then((response) => {
        if (response.status === 201) {
          openSnackBar('Dodano nowego administratora', Snackbars.SUCCESS);
          reset();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          const axiosError = err as AxiosError<IErrorResponse>;
          if (axiosError.response?.data.errors) {
            openSnackBar(axiosError.response?.data.errors[0], Snackbars.ERROR);
            return;
          }
          if (axiosError.response?.data.title)
            openSnackBar(axiosError.response?.data.title, Snackbars.ERROR);
        }
        throw err;
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.name}
            helperText={
              errors.name && errors.name?.message && errors.name.message
            }
            label="Imię"
            autoComplete="Imię"
            ref={null}
          />
        )}
      />
      <Controller
        name="surname"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.surname}
            helperText={
              errors.surname &&
              errors.surname?.message &&
              errors.surname.message
            }
            label="Nazwisko"
            autoComplete="Nazwisko"
            ref={null}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.email}
            helperText={
              errors.email && errors.email?.message && errors.email.message
            }
            label="E-mail"
            autoComplete="E-mail"
            ref={null}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.password}
            helperText={
              errors.password &&
              errors.password?.message &&
              errors.password.message
            }
            type="password"
            label="Hasło"
            autoComplete="Hasło"
            ref={null}
          />
        )}
      />
      <Controller
        name="confirmPassword"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.confirmPassword}
            helperText={
              errors.confirmPassword &&
              errors.confirmPassword?.message &&
              errors.confirmPassword.message
            }
            type="password"
            label="Potwierdź hasło"
            autoComplete="Potwierdź hasło"
            ref={null}
          />
        )}
      />
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.phoneNumber}
            helperText={
              errors.phoneNumber &&
              errors.phoneNumber?.message &&
              errors.phoneNumber.message
            }
            label="Numer telefonu"
            autoComplete="Numer telefonu"
            ref={null}
          />
        )}
      />
      <CustomButton>
        <Typography className={classes.submitText}>Dodaj nowe konto</Typography>
      </CustomButton>
    </form>
  );
};
