import React, { useEffect, useState, useRef } from 'react';
import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { CustomInput } from 'src/components/CustomInput';
import { CustomButton } from 'src/components/CustomButton';
import { useDeviceWizzardContext } from 'src/contexts/AddDeviceWizzard';

import { useCities } from 'src/hooks/useCities';
import { IStreets } from 'src/hooks/useStreets';
import { axiosInstace } from 'src/services/axiosInstance';
import { useDeviceInfoStepStyles } from './DeviceInfoStep.styles';
import { useGetBuildingsQuery, useGetFlatsQuery } from '../../../../../../api/locations';
import { useGetCustomers } from '../../../../../../api/customers';

type DEV_TYPE = 'heatMeter' | 'waterMeter' | '';
type NETWORK_TYPE = 'TTN' | 'Emitel' | '';

interface IFormInput {
  name: string;
  cityId: string;
  streetId: string;
  buildingId: string;
  flatId: string;
  description: string;
  deviceType: DEV_TYPE;
  networkType: NETWORK_TYPE;
}

const deviceInfoValidationSchema = yup.object().shape({
  name: yup
    .string()
    .max(255)
    .matches(/^([a-z0-9-]+)$/, 'Nieprawidłowy format identyfikatora')
    .required('Pole obowiązkowe'),
  cityId: yup.string().max(255).required('Pole obowiązkowe'),
  streetId: yup.string().max(255).required('Pole obowiązkowe'),
  buildingId: yup.string().max(255).required('Pole obowiązkowe'),
  flatId: yup.string().max(255).optional(),
  description: yup.string().max(255).optional(),
  deviceType: yup.string().oneOf(['heatMeter', 'waterMeter']).required(),
  networkType: yup.string().oneOf(['TTN', 'Emitel']).required()
});

interface DeviceInfoStepProps {
}

export const DeviceInfoStep: React.FC<DeviceInfoStepProps> = () => {
  const classes = useDeviceInfoStepStyles();
  const [streets, setStreets] = useState<IStreets[] | null>();
  const [selectedCity, setselectedCity] = useState<string | null>(null);
  const [selectedStreet, setSelectedStreet] = useState<string | null>(null);
  const [selectedBuilding, setSelectedBuilding] = useState<string | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
  const devNameRef = useRef<any>(null);
  const { data: buildings, refetch: refetchBuildings } = useGetBuildingsQuery({
    streetId: selectedStreet || '',
    tenantId: selectedCustomer || ''
  });
  const { data: flats, refetch: refetchFlats } = useGetFlatsQuery({
    buildingId: selectedBuilding || '',
    tenantId: selectedCustomer || ''
  });
  const { data: customers } = useGetCustomers();

  const { handleNext, updateDevice, deviceWizzard } = useDeviceWizzardContext();
  const { cities } = useCities({ searchDevice: false, tenatId: selectedCustomer });


  const handleCustomerSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCustomer(event.target.value as string);
  };

  const handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue('cityId', event.target.value as string);
    setselectedCity(event.target.value as string);
  };

  const handleAddressChange = (event: any, newAddressId: IStreets | null) => {
    if (newAddressId) {
      setValue('streetId', newAddressId.id);
      setSelectedStreet(newAddressId.id);
    }
  };

  const handleBuildingChange = (event: any, newAddressId: IStreets | null) => {
    if (newAddressId) {
      setValue('buildingId', newAddressId.id);
      setSelectedBuilding(newAddressId.id);
    }
  };

  const handleFlatChange = (event: any, newAddressId: IStreets | null) => {
    if (newAddressId) {
      setValue('flatId', newAddressId.id);
    }
  };

  useEffect(() => {
    if (selectedCity) {
      axiosInstace
        .get<IStreets[]>(
          `/api/address/cities/${selectedCity}/streets`, {
            headers: {
              'tenant-id': selectedCustomer || ''
            }
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setStreets(response.data);
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  }, [selectedCity, selectedCustomer]);


  useEffect(() => {
    if (selectedStreet) {
      refetchBuildings();
    }
  }, [refetchBuildings, selectedStreet]);

  useEffect(() => {
    if (selectedBuilding) {
      refetchFlats();
    }
  }, [refetchFlats, selectedBuilding]);

  useEffect(() => {
    if (devNameRef.current) {
      devNameRef.current.focus();
    }
  }, []);

  const {
      handleSubmit,
      control,
      setValue,
      formState: { errors }
    } = useForm<IFormInput>({
      defaultValues: {
        name: deviceWizzard?.name || '',
        buildingId: deviceWizzard?.buildingId || '',
        flatId: deviceWizzard?.flatId || '',
        description: deviceWizzard?.description || '',
        deviceType: 'waterMeter',
        networkType: 'Emitel'
      },
      resolver: yupResolver(deviceInfoValidationSchema)
    })
  ;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue('deviceType', event.target.value as DEV_TYPE);
  };

  const handleNetworkTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setValue('networkType', event.target.value as NETWORK_TYPE);
  };

  const onSubmit: SubmitHandler<IFormInput> = async ({
                                                       name,
                                                       streetId,
                                                       buildingId,
                                                       flatId,
                                                       description,
                                                       deviceType,
                                                       networkType
                                                     }) => {
    const lowerCharsName = name.toLocaleLowerCase();
    updateDevice({
      name: lowerCharsName,
      buildingId: buildingId,
      flatId: flatId,
      description,
      deviceType,
      networkType,
      tenantId: selectedCustomer || ''
    });
    handleNext();
  };


  return (
    <div>
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.name}
            helperText={errors.name && errors.name?.message}
            label="Identyfikator urządzenia"
            autoComplete="off"
            ref={devNameRef}
          />
        )}
      />
      <InputLabel htmlFor="city-select">Wybierz Klienta</InputLabel>
      <Select
        label="Klient"
        autoComplete="off"
        id="client-select"
        className={classes.select}
        placeholder="Wybierz klienta"
        onChange={handleCustomerSelect}
        style={{ marginBottom: '20px' }}
      >
        <MenuItem key="" value=""></MenuItem>
        {customers?.data?.map((customer) => (
          <MenuItem key={customer.id} value={customer.id}>
            <em>{customer.name}</em>
          </MenuItem>
        ))}
      </Select>
      <InputLabel htmlFor="city-select">Wybierz Miasto</InputLabel>
      <Controller
        name="cityId"
        control={control}
        render={({ field }) => (
          <>
            <Select
              {...field}
              error={!!errors.cityId}
              label="Miasto"
              autoComplete="off"
              id="city-select"
              ref={null}
              className={classes.select}
              placeholder="Wybierz miasto"
              onChange={handleCityChange}
              disabled={!selectedCustomer}
            >
              <MenuItem key="" value=""></MenuItem>
              {cities?.map((city) => (
                <MenuItem key={city.id} value={city.id}>
                  <em>{city.name}</em>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {errors.cityId && errors.cityId?.message && errors.cityId.message}
            </FormHelperText>
          </>
        )}
      />
      <Controller
        name="streetId"
        control={control}
        render={({ field }) => (
          <Autocomplete
            id="address-select"
            options={streets ? streets : []}
            onChange={handleAddressChange}
            getOptionLabel={(street) => street.name}
            autoHighlight={true}
            fullWidth={true}
            disabled={!selectedCity}
            renderInput={(params) => (
              <>
                <CustomInput
                  {...params}
                  error={!!errors.streetId}
                  helperText={
                    errors.streetId &&
                    errors.streetId?.message &&
                    errors.streetId.message
                  }
                  label="Adres"
                  placeholder="Wybierz adres"
                  autoComplete="Adres"
                  ref={null}
                />
                <FormHelperText>
                  {errors.streetId &&
                    errors.streetId?.message &&
                    errors.streetId.message}
                </FormHelperText>
              </>
            )}
          />
        )}
      />

      <Controller
        name="buildingId"
        control={control}
        render={({ field }) => (
          <Autocomplete
            id="buiulding-select"
            options={buildings?.data ? buildings?.data : []}
            onChange={handleBuildingChange}
            getOptionLabel={(building) => building.name}
            autoHighlight={true}
            fullWidth={true}
            disabled={!selectedStreet}
            renderInput={(params) => (
              <>
                <CustomInput
                  {...params}
                  error={!!errors.buildingId}
                  helperText={
                    errors.buildingId &&
                    errors.buildingId?.message &&
                    errors.buildingId.message
                  }
                  label="Budynek"
                  placeholder="Wybierz budynek"
                  autoComplete="off"
                  ref={null}
                />
                <FormHelperText>
                  {errors.buildingId &&
                    errors.buildingId?.message &&
                    errors.buildingId.message}
                </FormHelperText>
              </>
            )}
          />
        )}
      />
      <Controller
        name="flatId"
        control={control}
        render={({ field }) => (
          <Autocomplete
            id="flatNumber-select"
            options={flats?.data ? flats?.data : []}
            onChange={handleFlatChange}
            getOptionLabel={(flat) => flat.name}
            autoHighlight={true}
            fullWidth={true}
            disabled={!selectedBuilding}
            renderInput={(params) => (
              <>
                <CustomInput
                  {...params}
                  error={!!errors.flatId}
                  helperText={
                    errors.flatId &&
                    errors.flatId?.message &&
                    errors.flatId.message
                  }
                  label="Lokal"
                  required={false}
                  placeholder="Wybierz Lokal"
                  autoComplete="off"
                  ref={null}
                />
                <FormHelperText>
                  {errors.flatId &&
                    errors.flatId?.message &&
                    errors.flatId.message}
                </FormHelperText>
              </>
            )}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            required={false}
            error={!!errors.description}
            helperText={
              errors.description &&
              errors.description?.message &&
              errors.description.message
            }
            label="Notatka montażu"
            autoComplete="off"
            ref={null}
            rows={3}
            multiline={true}
          />
        )}
      />
      <InputLabel htmlFor="dev_type-select" style={{ marginTop: '20px' }}>
        Wybierz typ dodawanego urządzenia
      </InputLabel>
      <Controller
        name="deviceType"
        control={control}
        render={({ field }) => (
          <>
            <Select
              {...field}
              error={!!errors.deviceType}
              label="Typ urządzenia"
              autoComplete="Typ urządzenia"
              id="dev_type-select"
              ref={null}
              className={classes.select}
              placeholder="Wybierz typ urządzenia"
              onChange={handleChange}
              defaultValue=""
            >
              <MenuItem key="1" value="waterMeter">
                <em>Wodomierz</em>
              </MenuItem>
              <MenuItem key="2" value="heatMeter">
                <em>Ciepłomierz</em>
              </MenuItem>
            </Select>
            <FormHelperText>
              {errors.deviceType &&
                errors.deviceType?.message &&
                errors.deviceType.message}
            </FormHelperText>
          </>
        )}
      />
      <InputLabel htmlFor="network_type-select" style={{ marginTop: '30px' }}>
        Wybierz sieć
      </InputLabel>
      <Controller
        name="networkType"
        control={control}
        render={({ field }) => (
          <>
            <Select
              {...field}
              error={!!errors.networkType}
              label="Sieć"
              autoComplete="Sieć"
              id="network_type-select"
              ref={null}
              className={classes.select}
              placeholder="Wybierz sieć (TTN/Emitel)"
              onChange={handleNetworkTypeChange}
              defaultValue=""
            >
              <MenuItem key="1" value="Emitel">
                <em>Emitel</em>
              </MenuItem>
              <MenuItem key="2" value="TTN">
                <em>The Things Netwrok (TTN)</em>
              </MenuItem>
            </Select>
            <FormHelperText>
              {errors.networkType &&
                errors.networkType?.message &&
                errors.networkType.message}
            </FormHelperText>
          </>
        )}
      />
      <div className={classes.buttons}>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          className={classes.submitText}
        >
          Parametry mechaniczne
        </CustomButton>
      </div>
    </div>
  );
};
