import { makeStyles } from '@material-ui/core';

export const useDeviceActivationStyles = makeStyles(
  (theme) => ({
    container: {
      marginTop: '100px'
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center'
    },
    icon: {
      fontSize: '200px',
      color: 'green'
    },
    iconFailed: {
      fontSize: '200px',
      color: 'red'
    },
    buttons: {
      margin: `0 auto`,
      marginTop: theme.spacing(4),
      width: '50%'
    },
    submitText: {
      textDecoration: 'none',
      color: theme.palette.common.white,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  }),
  {
    name: 'DeviceActivation'
  }
);
