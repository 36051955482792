interface IConfig {
  api: string;
}

enum Environments {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
  STAGING = 'staging'
}

enum API {
  LOCALHOST = 'http://localhost:5000',
  PRODUCTION = 'https://backend.wodmar-systems.pl',
  STAGING = 'https://test.backend.wodmar-systems.pl'
}

export const getConfig = (): IConfig => {
  switch (process.env.REACT_APP_RUNNING_ENV) {
    case Environments.DEVELOPMENT:
      return {
        api: API.LOCALHOST
      }
    case Environments.PRODUCTION:
      return {
        api: API.PRODUCTION
      }
    case Environments.STAGING:
      return {
        api: API.STAGING
      }
    default:
      console.log('Invalid env');
  }
  return {
    api: API.LOCALHOST
  }
};
