import { axiosInstace } from 'src/services/axiosInstance';
import { AxiosResponse } from 'axios';

import { Endpoint } from '../../endpoint';

interface IGetFlatsRequest {
  buildingId: string;
  tenantId: string;
}

interface IFlatResponse {
  name: string;
  id: string;
}

export const getFlats = ({ buildingId, tenantId }: IGetFlatsRequest): Promise<AxiosResponse<IFlatResponse[]>> => {
  return axiosInstace.get(Endpoint.FLATS.replace(':buildingId', buildingId), {
    headers: {
      'tenant-id': tenantId || ''
    }
  });
};
