import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { QueryClientProvider } from 'react-query';

import { MainRoutes } from './routes/MainRoutes';
import { CustomizedSnackbars } from './components/SnackBar/Snackbar';
import { SnackbarProvider } from './components/SnackBar/SnackbarProvider';
import { UserProvider } from './contexts/UserProvider';
import { useAppStyles } from './App.styles';
import { queryClient } from './react-query';

const App: React.FC = (): JSX.Element => {
  const classes = useAppStyles();

  return (
    <QueryClientProvider client={queryClient}>
      <div className={classes.container}>
        <CssBaseline>
          <SnackbarProvider>
            <UserProvider>
              <CustomizedSnackbars />
              <Router>
                <MainRoutes />
              </Router>
            </UserProvider>
          </SnackbarProvider>
        </CssBaseline>
      </div>
    </QueryClientProvider>
  );
};

export { App };
