import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Grid, Typography } from '@material-ui/core';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbarContext } from 'src/components/SnackBar/SnackbarProvider';
import { axiosInstace, IErrorResponse } from 'src/services/axiosInstance';
import { Snackbars } from 'src/components/SnackBar/Snackbar.types';
import { AxiosError } from 'axios';
import { CustomInput } from 'src/components/CustomInput';
import { CustomButton } from 'src/components/CustomButton';
import { Logo } from 'src/components/Logo';
import { Routes } from 'src/routes/routes';

import { useResetPasswordStyles } from './ResetPassword.styles';

interface IFormInput {
  password: string;
  confirmPassword: string;
}

const newPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .max(100, 'Hasło nie może przekraczać 100 znaków')
    .min(8, 'Hasło musi mieć conajmniej 8 znaków')
    .required('Hasło jest wymagane'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Hasła muszą być takie same')
});

interface NewPasswordProps {}

export const NewPassword: React.FC<NewPasswordProps> = () => {
  const classes = useResetPasswordStyles();
  const [token, setToken] = useState<string | null>(null);
  const { openSnackBar } = useSnackbarContext();
  const { search } = useLocation();
  const history = useHistory();
  const queryToken = new URLSearchParams(search).get('token');

  useEffect(() => {
    if (queryToken) {
      setToken(queryToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryToken]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<IFormInput>({
    resolver: yupResolver(newPasswordSchema),
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });

  const onSubmit: SubmitHandler<IFormInput> = ({
    password,
    confirmPassword
  }) => {
    axiosInstace
      .post('/api/account/password/reset', {
        token,
        password,
        confirmPassword
      })
      .then((response) => {
        reset();
        if (response.status === 204) {
          history.replace(Routes.LOGIN);
          openSnackBar('Hasło zmienione', Snackbars.SUCCESS);
        }
      })
      .catch((err) => {
        reset();
        if (err && err.response) {
          const axiosError = err as AxiosError<IErrorResponse>;
          if (axiosError.response?.data.errors) {
            openSnackBar(axiosError.response?.data.errors[0], Snackbars.ERROR);
            return;
          }
          if (axiosError.response?.data.title) {
            openSnackBar(axiosError.response?.data.title, Snackbars.ERROR);
            return;
          }
        }
        throw err;
      });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={classes.container}
    >
      {token ? (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <Logo width="300" />
          <Typography align="center" className={classes.headerText}>
            Nowe hasło
          </Typography>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <CustomInput
                {...field}
                error={!!errors.password}
                helperText={
                  errors.password &&
                  errors.password?.message &&
                  errors.password.message
                }
                type="password"
                label="Hasło"
                autoComplete="Hasło"
                ref={null}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <CustomInput
                {...field}
                error={!!errors.confirmPassword}
                helperText={
                  errors.confirmPassword &&
                  errors.confirmPassword?.message &&
                  errors.confirmPassword.message
                }
                type="password"
                label="Potwierdź hasło"
                autoComplete="Potwierdź hasło"
                ref={null}
              />
            )}
          />
          <div>
            <CustomButton>
              <Typography className={classes.submitText}>
                Ustaw nowe hasło
              </Typography>
            </CustomButton>
          </div>
        </form>
      ) : (
        <Typography> Niepoprawny link</Typography>
      )}
    </Grid>
  );
};
