import { makeStyles } from '@material-ui/core';

export const useLoginStyles = makeStyles(
  (theme) => ({
    container: {
      minHeight: '100vh',
      padding: '0 10px'
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submitText: {
      textDecoration: 'none',
      color: theme.palette.common.white
    }
  }),
  {
    name: 'Login'
  }
);
