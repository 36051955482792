import axios from 'axios';
import { getConfig } from 'src/config';
import { destroyCookie, getCookie } from './cookiesService';
export interface IErrorResponse {
  title: string;
  errors: null | string[];
  status: number;
  traceId: string;
  type: string;
}
const { api } = getConfig();

const axiosInstace = axios.create({
  baseURL: api.toString(),
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstace.defaults.headers.common['Content-Type'] = 'application/json';

axiosInstace.interceptors.request.use(
  (config) => {
    const accessToken = getCookie('at');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstace.interceptors.response.use(undefined, function (error) {
  const { api } = getConfig();
  const originalRequest = error.config;
  if (error.response?.status === 500) {
    return Promise.resolve('Serwer nie odpowiada');
  }
  if (error.response?.status === 401) {
    destroyCookie('at');
    return Promise.reject(error);
  }
  if (error.response?.status === 404) {
    return Promise.reject(error);
  }
  if (
    error.response?.status === 401 &&
    originalRequest.url === `${api}/users/refreshtoken`
  ) {
    return Promise.reject(originalRequest.url);
  }
  return Promise.reject(error);
});

export { axiosInstace };
