import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSnackbarContext } from './SnackbarProvider';

const SNACKBAR_AUTO_HIDE_DURATION = 5000;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const Alert: React.FC<AlertProps> = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const CustomizedSnackbars: React.FC = () => {
  const classes = useStyles();
  const { isOpen, handleClose, snackBarType, snackBarMessage } =
    useSnackbarContext();

  return (
    <div className={classes.root}>
      <Snackbar
        open={isOpen}
        autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert severity={snackBarType}>{snackBarMessage}</Alert>
      </Snackbar>
    </div>
  );
};
