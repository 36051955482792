import React, { useState } from 'react';
import clsx from 'clsx';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from '@material-ui/core';
import { MenuItems } from 'src/pages/Dashboard/Menu';
import { Logo } from '../Logo';
import { useMobileMenuStyles } from './MobileMenu.styles';

interface MobileMenuProps {}

export const MobileMenu: React.FC<MobileMenuProps> = () => {
  const classes = useMobileMenuStyles();
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div className={classes.container}>
      <div className={classes.menuMobileTop}>
        <Logo width="150" />
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => setShowOptions((prevState) => !prevState)}
        >
          {!showOptions ? <MenuIcon /> : <MenuOpenIcon />}
        </IconButton>
      </div>
      <MenuItems
        mobileTransition={clsx(
          showOptions ? classes.menuMobileOpened : classes.menuMobile
        )}
      />
    </div>
  );
};
