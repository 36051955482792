import React from 'react';
import clsx from 'clsx';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { CustomButton } from 'src/components/CustomButton';
import { useDeviceWizzardContext } from 'src/contexts/AddDeviceWizzard';
import { useHeatMeterParam } from 'src/hooks/useHeatMeterParam';
import {
  InputLabel,
  MenuItem,
  Select,
  FormHelperText
} from '@material-ui/core';
import { useDeviceInfoStepStyles } from '../DeviceInfoStep/DeviceInfoStep.styles';
import { useWaterMeterParam } from 'src/hooks/useWaterMeterParam';

enum AssemblyMethod {
  POWER = 'Power',
  RETURN = 'Return',
  EMPTY = ''
}

interface IFormInput {
  diameters: string;
  measurementClasses: string;
  flows: string;
  lengths?: string;
  producers: string;
  types?: string;
  assemblyMethod: AssemblyMethod;
}

const heatMeterParamsValidationSchema = yup.object().shape({
  diameters: yup.string().max(255).required('Pole obowiązkowe'),
  measurementClasses: yup.string().max(255).required('Pole obowiązkowe'),
  flows: yup.string().max(255).required('Pole obowiązkowe'),
  lengths: yup.string().max(255).optional(),
  producers: yup.string().max(255).required('Pole obowiązkowe'),
  types: yup.string().max(255).optional(),
  assemblyMethod: yup.string().max(255)
});

const waterMeterParamsValidationSchema = yup.object().shape({
  diameters: yup.string().max(255).required('Pole obowiązkowe'),
  measurementClasses: yup.string().max(255).required('Pole obowiązkowe'),
  flows: yup.string().max(255).required('Pole obowiązkowe'),
  lengths: yup.string().max(255).optional(),
  producers: yup.string().max(255).required('Pole obowiązkowe'),
  types: yup.string().max(255).optional()
});

export const DeviceParams: React.FC = () => {
  const classes = useDeviceInfoStepStyles();
  const { handleNext, handleBack, deviceWizzard, updateDevice } =
    useDeviceWizzardContext();
  const { heatMeterParam } = useHeatMeterParam();
  const { waterMeterParam } = useWaterMeterParam();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<IFormInput>({
    defaultValues: {
      diameters: '',
      measurementClasses: '',
      flows: '',
      lengths: '',
      producers: '',
      types: '',
      assemblyMethod: AssemblyMethod.EMPTY
    },
    resolver: yupResolver(deviceWizzard?.deviceType === 'heatMeter' ? heatMeterParamsValidationSchema : waterMeterParamsValidationSchema)
  });

  const handleDiameterChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setValue('diameters', event.target?.value as string);
  };

  const handleMeasurementClassesChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setValue('measurementClasses', event.target?.value as string);
  };

  const handleFlowsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue('flows', event.target?.value as string);
  };

  const handleLengthsChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setValue('lengths', event.target?.value as string);
  };

  const handleProducersChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setValue('producers', event.target?.value as string);
  };

  const handleAssemblyChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setValue('assemblyMethod', event.target?.value as AssemblyMethod);
  };

  const handleTypesChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue('types', event.target?.value as string);
  };

  const onSubmit: SubmitHandler<IFormInput> = async ({
                                                       diameters,
                                                       producers,
                                                       types,
                                                       lengths,
                                                       flows,
                                                       measurementClasses,
                                                       assemblyMethod
                                                     }) => {
    if (deviceWizzard?.deviceType === 'waterMeter') {
      updateDevice({
        waterMeterProperties: {
          type: types,
          diameter: diameters,
          measurementClass: measurementClasses,
          flow: flows,
          producer: producers
        }
      });
    } else {
      updateDevice({
        heatMeterProperties: {
          diameter: diameters,
          measurementClass: measurementClasses,
          flow: flows,
          length: lengths,
          producer: producers,
          assemblyMethod
        }
      });
    }

    handleNext();
  };

  return (
    <div>
      <div>
        <InputLabel htmlFor="diameter-select">Wybierz średnice</InputLabel>
        <Controller
          name="diameters"
          control={control}
          render={({ field }) => (
            <>
              <FormHelperText>
                {errors.diameters &&
                  errors.diameters?.message &&
                  errors.diameters.message}
              </FormHelperText>
              <Select
                {...field}
                error={!!errors.diameters}
                label="Średnica"
                autoComplete="Średnica"
                id="diameter-select"
                ref={null}
                className={clsx(classes.select, classes.selectors)}
                placeholder="Wybierz średnice"
                onChange={handleDiameterChange}
              >
                <MenuItem key="" value=""></MenuItem>
                {deviceWizzard?.deviceType === 'heatMeter'
                  ? heatMeterParam?.diameters?.map((param) => (
                    <MenuItem key={param.id} value={param.id}>
                      <em>{param.value}</em>
                    </MenuItem>
                  ))
                  : waterMeterParam?.diameters?.map((param) => (
                    <MenuItem key={param.id} value={param.id}>
                      <em>{param.value}</em>
                    </MenuItem>
                  ))}
              </Select>
            </>
          )}
        />
        <InputLabel htmlFor="measurementClasses-select">
          Wybierz klase pomiarową
        </InputLabel>
        <Controller
          name="measurementClasses"
          control={control}
          render={({ field }) => (
            <>
              <FormHelperText>
                {errors.measurementClasses &&
                  errors.measurementClasses?.message &&
                  errors.measurementClasses.message}
              </FormHelperText>
              <Select
                {...field}
                error={!!errors.measurementClasses}
                label="Klasa pomiarowa"
                autoComplete="Klasa pomiarowa"
                id="measurementClasses-select"
                ref={null}
                className={clsx(classes.select, classes.selectors)}
                placeholder="Wybierz klase pomiarową"
                onChange={handleMeasurementClassesChange}
              >
                <MenuItem key="measurementClasses" value=""></MenuItem>

                {deviceWizzard?.deviceType === 'heatMeter'
                  ? heatMeterParam?.measurementClasses?.map((param) => (
                    <MenuItem key={param.id} value={param.id}>
                      <em>{param.value}</em>
                    </MenuItem>
                  ))
                  : waterMeterParam?.measurementClasses?.map((param) => (
                    <MenuItem key={param.id} value={param.id}>
                      <em>{param.value}</em>
                    </MenuItem>
                  ))}
              </Select>
            </>
          )}
        />
        <InputLabel htmlFor="flows-select">Wybierz przepływ</InputLabel>
        <Controller
          name="flows"
          control={control}
          render={({ field }) => (
            <>
              <FormHelperText>
                {errors.flows && errors.flows?.message && errors.flows.message}
              </FormHelperText>
              <Select
                {...field}
                error={!!errors.flows}
                label="Przepływ"
                autoComplete="Przepływ"
                id="flows-select"
                className={clsx(classes.select, classes.selectors)}
                ref={null}
                placeholder="Wybierz przepływ"
                onChange={handleFlowsChange}
              >
                <MenuItem key="" value=""></MenuItem>
                {deviceWizzard?.deviceType === 'heatMeter'
                  ? heatMeterParam?.flows?.map((param) => (
                    <MenuItem key={param.id} value={param.id}>
                      <em>{param.value}</em>
                    </MenuItem>
                  ))
                  : waterMeterParam?.flows?.map((param) => (
                    <MenuItem key={param.id} value={param.id}>
                      <em>{param.value}</em>
                    </MenuItem>
                  ))}
              </Select>
            </>
          )}
        />
        {deviceWizzard?.deviceType === 'heatMeter' ? (
          <>
            <InputLabel htmlFor="lengths-select">
              Wybierz długość montażową
            </InputLabel>
            <Controller
              name="lengths"
              control={control}
              render={({ field }) => (
                <>
                  <FormHelperText>
                    {errors.lengths &&
                      errors.lengths?.message &&
                      errors.lengths.message}
                  </FormHelperText>
                  <Select
                    {...field}
                    error={!!errors.lengths}
                    label="Długość"
                    autoComplete="Długość"
                    id="lengths-select"
                    className={clsx(classes.select, classes.selectors)}
                    ref={null}
                    placeholder="Wybierz długość montażową"
                    onChange={handleLengthsChange}
                  >
                    <MenuItem key="" value=""></MenuItem>
                    {heatMeterParam?.lengths?.map((param) => (
                      <MenuItem key={param.id} value={param.id}>
                        <em>{param.value}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </>
        ) : (
          <>
            <InputLabel htmlFor="types-select">
              Wybierz typ wodomierza
            </InputLabel>
            <Controller
              name="types"
              control={control}
              render={({ field }) => (
                <>
                  <FormHelperText>
                    {errors.types &&
                      errors.types?.message &&
                      errors.types.message}
                  </FormHelperText>
                  <Select
                    {...field}
                    error={!!errors.types}
                    label="Typ"
                    autoComplete="Typ"
                    id="types-select"
                    className={clsx(classes.select, classes.selectors)}
                    ref={null}
                    placeholder="Wybierz długość montażową"
                    onChange={handleTypesChange}
                  >
                    <MenuItem key="" value=""></MenuItem>
                    {waterMeterParam?.types?.map((param) => (
                      <MenuItem key={param.id} value={param.id}>
                        <em>{param.value}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </>
        )}

        {
          deviceWizzard?.deviceType === 'heatMeter' && (
            <>
              <InputLabel htmlFor="producers-select">Typ montażu</InputLabel>
              <Controller
                name="assemblyMethod"
                control={control}
                render={({ field }) => (
                  <>
                    <FormHelperText>
                      {errors.assemblyMethod &&
                        errors.assemblyMethod?.message &&
                        errors.assemblyMethod.message}
                    </FormHelperText>
                    <Select
                      {...field}
                      error={!!errors.assemblyMethod}
                      label="Typ montażu"
                      autoComplete="Typ montażu"
                      id="assemly-method-select"
                      className={clsx(classes.select, classes.selectors)}
                      ref={null}
                      placeholder="Wybierz typ montażu"
                      onChange={handleAssemblyChange}
                    >
                      <MenuItem key={AssemblyMethod.EMPTY} value={AssemblyMethod.EMPTY}></MenuItem>
                      <MenuItem key={AssemblyMethod.POWER} value={AssemblyMethod.POWER}>
                        <em>Na zasilaniu</em>
                      </MenuItem>
                      <MenuItem key={AssemblyMethod.RETURN} value={AssemblyMethod.RETURN}>
                        <em>Na powrocie</em>
                      </MenuItem>
                    </Select>
                  </>
                )}
              />
            </>
          )
        }

        <InputLabel htmlFor="producers-select">Wybierz producenta</InputLabel>
        <Controller
          name="producers"
          control={control}
          render={({ field }) => (
            <>
              <FormHelperText>
                {errors.producers &&
                  errors.producers?.message &&
                  errors.producers.message}
              </FormHelperText>
              <Select
                {...field}
                error={!!errors.producers}
                label="Producent"
                autoComplete="Producent"
                id="producers-select"
                className={clsx(classes.select, classes.selectors)}
                ref={null}
                placeholder="Wybierz producenta"
                onChange={handleProducersChange}
              >
                <MenuItem key="" value=""></MenuItem>
                {deviceWizzard?.deviceType === 'heatMeter'
                  ? heatMeterParam?.producers?.map((param) => (
                    <MenuItem key={param.id} value={param.id}>
                      <em>{param.value}</em>
                    </MenuItem>
                  ))
                  : waterMeterParam?.producers?.map((param) => (
                    <MenuItem key={param.id} value={param.id}>
                      <em>{param.value}</em>
                    </MenuItem>
                  ))}
              </Select>
            </>
          )}
        />


        <div className={classes.buttons}>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={handleBack}
            className={classes.submitText}
          >
            Wróć
          </CustomButton>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            className={classes.submitText}
          >
            Parametry radiowe
          </CustomButton>
        </div>
      </div>
    </div>
  )
    ;
};
