import Cookies from 'universal-cookie';

interface ISetCookie {
  name: string;
  token: string;
  expirationTime: Date;
}

const cookies = new Cookies();

export const setCookie = ({ name, token, expirationTime }: ISetCookie) => {
  cookies.set(name, token, {
    path: '/',
    expires: expirationTime
  });
};

export const getCookie = (cookieName: string) => {
  return cookies.get(cookieName);
};

export const destroyCookie = (cookieName: string) => {
  cookies.remove(cookieName, {
    path: '/'
  });
};
