import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { getTommorowDate } from 'src/helpers/tommorowDate';
import { CustomButton } from 'src/components/CustomButton';
import { CustomInput } from 'src/components/CustomInput';
import { axiosInstace, IErrorResponse } from 'src/services/axiosInstance';
import { ILoggedUser, useUserContext } from 'src/contexts/UserProvider';
import { Snackbars } from 'src/components/SnackBar/Snackbar.types';
import { useSnackbarContext } from 'src/components/SnackBar/SnackbarProvider';
import { getCookie, setCookie } from 'src/services/cookiesService';
import { useMyProfileStyles } from './MyProfile.styles';

interface IFormInput {
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
}

const profileValidationSchema = yup.object().shape({
  name: yup.string().max(255).required('Pole obowiązkowe'),
  surname: yup.string().max(255).required('Pole obowiązkowe'),
  email: yup.string().email('Niepoprawny e-mail').required('Pole obowiązkowe'),
  phoneNumber: yup
    .string()
    .min(9, 'Numer telefonu powinien mieć conajmniej 9 znaków')
    .matches(/^[0-9]+$/, 'Numer telefonu powinien składać się tylko z cyfr')
});

export const ProfileForm: React.FC = (): JSX.Element => {
  const classes = useMyProfileStyles();
  const { user, handleUser } = useUserContext();
  const [isProfileUpdated, setIsProfileUpdated] = useState<boolean>(false);
  const { openSnackBar } = useSnackbarContext();
  const history = useHistory();

  const getMyData = async () => {
    axiosInstace
      .get<ILoggedUser>('/api/account/my')
      .then((response) => {
        if (response.status === 200) {
          setCookie({
            name: 'user',
            token: JSON.stringify(response.data),
            expirationTime: getTommorowDate()
          });
          handleUser(response.data);
        } else history.replace('/');
      })
      .catch((err) => {
        if (err && err.response) {
          const axiosError = err as AxiosError<IErrorResponse>;
          console.error(axiosError.response?.data);
        }
        throw err;
      });
  };

  useEffect(() => {
    getMyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileUpdated]);

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IFormInput>({
    defaultValues: {
      name: user?.name || getCookie('user')?.name,
      surname: user?.surname || getCookie('user')?.surname,
      email: user?.email || getCookie('user')?.email,
      phoneNumber: user?.phoneNumber || getCookie('user')?.phoneNumber
    },
    resolver: yupResolver(profileValidationSchema)
  });

  const onSubmit: SubmitHandler<IFormInput> = async ({
    name,
    surname,
    email,
    phoneNumber
  }) => {
    axiosInstace
      .put<IFormInput>(
        '/api/account/my/data',
        email !== user?.email
          ? {
              name,
              surname,
              email,
              phoneNumber
            }
          : {
              name,
              surname,
              phoneNumber
            }
      )
      .then((response) => {
        if (response.status === 204) {
          handleUser(response.data);
          openSnackBar('Zaktualizowano profil', Snackbars.SUCCESS);
          setIsProfileUpdated((preValue) => !preValue);
        }
      })
      .catch((err) => {
        if (err && err.response) {
          const axiosError = err as AxiosError<IErrorResponse>;
          if (axiosError.response?.data.errors) {
            openSnackBar(axiosError.response?.data.errors[0], Snackbars.ERROR);
            return;
          }
          if (axiosError.response?.data.title)
            openSnackBar(axiosError.response?.data.title, Snackbars.ERROR);
        }
        throw err;
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Typography variant="h6" align="center">
        Profil użytkownika - aktualizuj swoje dane
      </Typography>
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.name}
            helperText={
              errors.name && errors.name?.message && errors.name.message
            }
            label="Imię"
            autoComplete="Imię"
            ref={null}
          />
        )}
      />
      <Controller
        name="surname"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.surname}
            helperText={
              errors.surname &&
              errors.surname?.message &&
              errors.surname.message
            }
            label="Nazwisko"
            autoComplete="Nazwisko"
            ref={null}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.email}
            helperText={
              errors.email && errors.email?.message && errors.email.message
            }
            label="E-mail"
            autoComplete="E-mail"
            ref={null}
          />
        )}
      />
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            error={!!errors.phoneNumber}
            helperText={
              errors.phoneNumber &&
              errors.phoneNumber?.message &&
              errors.phoneNumber.message
            }
            label="Numer telefonu"
            autoComplete="Numer telefonu"
            ref={null}
          />
        )}
      />
      <CustomButton>
        <Typography className={classes.submitText}>
          {' '}
          Aktualizuj profil{' '}
        </Typography>
      </CustomButton>
    </form>
  );
};
