import { axiosInstace } from 'src/services/axiosInstance';
import { AxiosResponse } from 'axios';

import { Endpoint } from '../../endpoint';

interface IGetBuildingsRequest {
  streetId: string;
  tenantId: string
}

interface IBuildingResponse {
  name: string;
  id: string;
}


export const getBuildings = ({ streetId, tenantId }: IGetBuildingsRequest): Promise<AxiosResponse<IBuildingResponse[]>> => {
  return axiosInstace.get(Endpoint.BUILDINGS.replace(':streetId', streetId), {
    headers: {
      'tenant-id': tenantId || ''
    }
  });
};
