import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Grid, TableCell, TableRow } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { countPages } from 'src/helpers/countPages';
import { IPagination } from 'src/interfaces/IPagination';
import { ILoggedUser } from 'src/contexts/UserProvider';
import { axiosInstace, IErrorResponse } from 'src/services/axiosInstance';
import { CustomTable } from 'src/components/CustomTable';
import { useAccountsStyles } from './Accounts.styles';

interface AccountsProps {}

export const Accounts: React.FC<AccountsProps> = () => {
  const classes = useAccountsStyles();
  const [usersList, setUsersList] = useState<ILoggedUser[]>();
  const [page, setPage] = useState<number>(1);
  const [totalSize, setTotalSize] = useState<number>(0);
  const limit = 5;

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    axiosInstace
      .get<IPagination<ILoggedUser[]>>(
        `/api/account?limit=${limit}&offset=${
          page === 1 ? 0 : (page - 1) * limit
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          setUsersList(response.data.results);
          setTotalSize(response.data.totalSize);
        }
      })
      .catch((err) => {
        if (err && err.response) {
          const axiosError = err as AxiosError<IErrorResponse>;
          console.error(axiosError);
        }
        throw err;
      });
  }, [page]);

  return (
    <>
      {usersList && totalSize && (
        <Grid className={classes.container}>
          <CustomTable
            headTitles={['Imię', 'Nazwisko', 'Email', 'Telefon']}
            rows={usersList.map((user) => (
              <TableRow key={user.email} hover>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.surname}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phoneNumber}</TableCell>
              </TableRow>
            ))}
          />
          {countPages(totalSize, limit) > 0 && (
            <Pagination
              count={countPages(totalSize, limit)}
              page={page}
              onChange={handleChange}
              defaultPage={1}
            />
          )}
        </Grid>
      )}
    </>
  );
};
