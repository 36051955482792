import { makeStyles } from '@material-ui/core';

export const useNewAccountsStyles = makeStyles(
  (theme) => ({
    container: {
      width: '100%',
      height: '100%'
    },
    submitText: {
      textDecoration: 'none',
      color: theme.palette.common.white
    },
    form: {
      width: '50%', // Fix IE 11 issue.
      margin: '0 20px'
    }
  }),
  {
    name: 'NewAccounts'
  }
);
