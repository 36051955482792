import { makeStyles } from '@material-ui/core';

export const useCustomButtonStyles = makeStyles(
  (theme) => ({
    button: {
      margin: theme.spacing(3, 0, 2),
      backgroundColor: theme.palette.common.black,
      '&:hover': {
        //you want this to be the same as the backgroundColor above
        backgroundColor: theme.palette.grey[900]
      }
    }
  }),
  {
    name: 'CustomButton'
  }
);
