import React, { useState, useContext, ReactNode } from 'react';
import { Snackbars } from './Snackbar.types';

interface ISnackBarContext {
  isOpen: boolean;
  snackBarType: Snackbars;
  snackBarMessage: string;
  openSnackBar: (message: string, snackBarType: Snackbars) => void;
  handleClose: () => void;
}

export const useSnackbarContext = () => {
  return useContext(SnackbarContext);
};

const SnackbarContext = React.createContext({} as ISnackBarContext);

export const SnackbarProvider: React.FC<ReactNode> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>('');
  const [snackBarType, setSnackBarType] = useState<Snackbars>(Snackbars.INFO);

  const openSnackBar = (message: string, snackBarType: Snackbars) => {
    setSnackBarMessage(message);
    setSnackBarType(snackBarType);
    setIsOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  return (
    <SnackbarContext.Provider
      value={{
        isOpen,
        snackBarType,
        snackBarMessage,
        openSnackBar,
        handleClose
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};
