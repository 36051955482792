import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Route, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  Grid,
  List,
  Divider,
  Drawer,
  IconButton,
  CssBaseline
} from '@material-ui/core';
import { useSnackbarContext } from 'src/components/SnackBar/SnackbarProvider';
import { ILoggedUser, useUserContext } from 'src/contexts/UserProvider';
import { Snackbars } from 'src/components/SnackBar/Snackbar.types';
import { destroyCookie, getCookie } from 'src/services/cookiesService';
import { Logo } from 'src/components/Logo';
import { MobileMenu } from 'src/components/MobileMenu/MobileMenu';
import { axiosInstace, IErrorResponse } from 'src/services/axiosInstance';
import { Routes } from 'src/routes/routes';
import { Settings } from './Settings';
import { MenuItems } from './Menu';
import { MyProfile } from './MyProfile';
import { useDashboardStyles } from './Dashboard.styles';
import { useShowMobile } from 'src/hooks/useShowMobile';
import { Devices } from './Devices';

export const Dashboard: React.FC = (): JSX.Element => {
  const classes = useDashboardStyles();
  const history = useHistory();
  const location = useLocation();
  const { showMobileMenu } = useShowMobile();
  const { handleUser, user } = useUserContext();
  const { openSnackBar } = useSnackbarContext();

  useEffect(() => {
    if (!user || !getCookie('at')) {
      axiosInstace
        .get<ILoggedUser>('/api/account/my')
        .then((response) => {
          if (response.status === 200) {
            handleUser(response.data);
          }
        })
        .catch((err) => {
          if (err && err.response) {
            const axiosError = err as AxiosError<IErrorResponse>;
            if (axiosError.response?.data.title)
              openSnackBar(axiosError.response?.data.title, Snackbars.ERROR);
            if (axiosError.response?.status === 401) {
              destroyCookie('at');
              history.replace('/');
            }
          }
          throw err;
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const [open, setOpen] = useState(true);

  const handleDrawerOpen = (): void => {
    setOpen(true);
  };

  const handleDrawerClose = (): void => {
    setOpen(false);
  };

  return (
    <div className={clsx(!showMobileMenu ? classes.root : classes.mobileRoot)}>
      <CssBaseline />
      {!showMobileMenu ? (
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            {open ? (
              <>
                <Logo width="100" />
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              </>
            ) : (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
            )}
          </div>
          <Divider />
          <List>
            <MenuItems />
          </List>
        </Drawer>
      ) : (
        <MobileMenu />
      )}
      <div className={classes.content}>
        <Grid>
          <Route path={Routes.MANAGE} component={Settings} />
          <Route path={Routes.DEVICES} component={Devices} />
          <Route path={Routes.PROFILE} component={MyProfile} />
        </Grid>
      </div>
    </div>
  );
};
