import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Snackbars } from 'src/components/SnackBar/Snackbar.types';
import { axiosInstace, IErrorResponse } from 'src/services/axiosInstance';

export interface ICities {
  id: string;
  name: string;
}

interface IUseCitiesProp {
  searchDevice: boolean;
  tenatId: string | null;
}

export const useCities = ({ searchDevice, tenatId }: IUseCitiesProp) => {
  const [cities, setCities] = useState<ICities[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getCities = useCallback(() => {
    setIsLoading(true);
    axiosInstace
      .get<ICities[]>(
        searchDevice === true
          ? '/api/address/cities?withStreets=true'
          : '/api/address/cities', {
          headers: {
            'tenant-id': tenatId
          }
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setCities(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err && err.response) {
          const axiosError = err as AxiosError<IErrorResponse>;
          setIsLoading(false);
          if (axiosError.response?.data.errors) {
            console.error(axiosError.response?.data.errors[0], Snackbars.ERROR);
          }
          if (axiosError.response?.data.title)
            console.error(axiosError.response?.data.title, Snackbars.ERROR);
        }
        throw err;
      });
  }, [searchDevice, tenatId]);

  useEffect(() => {
    if (tenatId) {
      getCities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenatId]);

  return {
    cities,
    isLoading
  };
};
