import { makeStyles } from '@material-ui/core';

export const useLoginFormStyles = makeStyles(
  (theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submitText: {
      textDecoration: 'none',
      color: theme.palette.common.white
    },
    submitTextPassword: {
      textDecoration: 'underline',
      color: theme.palette.common.black
    }
  }),
  {
    name: 'LoginForm'
  }
);
